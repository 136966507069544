import React from 'react';
import ReactTooltip from 'react-tooltip';

import SEO from '../components/SEO';
import Nav from '../components/nav';
import Hero from '../components/Hero';
import Reading from '../components/Reading';
import Columns from '../components/Columns';
import Footer from '../components/Footer';

class Index extends React.Component {
  render() {
    return (
      <>
        <SEO title="" keywords={['']} pathname="/" />
        <Nav />
        <Hero />
        <Columns />
        <Reading />
        <Footer />
        <ReactTooltip effect="solid" />
      </>
    );
  }
}

export default Index;

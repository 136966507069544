import React, { useState, useRef, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Logo from './Logo-inverted-horizontal.svg';
import Form from './Form';

import init from './Hero-canvas';

const styles = require('./Hero-styles.module.css');

export default () => {
  const canvas = useRef(null);

  const [canvasInitialized, setCanvasInitialized] = useState(false);

  useEffect(() => {
    if (!canvasInitialized) {
      init(canvas.current, { maxRadius: 2, vy: -0.25, shootingStarInterval: 200 });
      setCanvasInitialized(true);
    }
  });

  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className={styles.hero}>
          <canvas ref={canvas} className={styles.canvas} />

          <div className={styles.row}>
            <div className={styles.col}>
              <header>
                <h1>
                  <Logo />
                  {data.site.siteMetadata.company} design tokens and design system generator
                </h1>
                <h2>{data.site.siteMetadata.tagline}</h2>
              </header>

              <div className={styles.tagline}>
                <p>
                  Extract design tokens from websites and use them in code and in your design tool.
                  <br />
                  <br />
                  <strong>Free</strong> app for MacOS, Windows &amp; Linux.
                </p>
              </div>
              <Form />
            </div>
            <div className={styles.col}>
              <Img
                alt="Screenshot of Superposition"
                title="Screenshot of Superposition"
                className={styles.image}
                fluid={data.file.childImageSharp.fluid}
                style={{ maxWidth: data.file.childImageSharp.fluid.presentationWidth }}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        company
        title
        tagline
        description
        author
      }
    }
    file: file(relativePath: { eq: "images/overview.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024, srcSetBreakpoints: [320, 375, 768, 1024, 1024]) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`;

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


const styles = require('./Columns-styles.module.css');

const Columns = () => (
  <StaticQuery
    query={query}
    render={data => (
      <section className={styles.columns}>
        <div className={styles.col}>
          <h2>Your website holds your design tokens</h2>
          <p>
            Design tokens are the smallest parts of your design: colors, typography, spacing etc. With them you can make
            consistent experiences across different parts of your website and app and even across projects.
          </p>

          <h2>Use them anywhere</h2>
          <p>
            Superposition extracts the design tokens your site already has and exports them to CSS, Scss, JavaScript,
            Figma and Adobe XD with Sketch, Swift and Android exports on the way.
          </p>
          <p>
            From the overview of each group, simply click on a token to add the CSS value to your clipboard for easy
            usage.
          </p>

          <h2>
            Create, document and export your design system <span>Soon</span>
          </h2>
          <p>
            Supercharge your design system process by filling in the initial tokens and components right from your
            website or app.
          </p>
          <p>
            Create your design tokens and components, add usage instruction and export it into a beautifully documented
            design system you can share with your client or team.
          </p>
        </div>
        <div className={styles.col}>
          <h2>Free download</h2>
          <p>
            Superposition is a free download and will remain free, made possible thanks to the Adobe Fund for Design and
            Polypane.
          </p>
          <p>The Superposition Design system generator will be a paid extension.</p>
          <h2>Supported by</h2>
          <p className={styles.fw}>
            <Img fixed={data.adobeFund.childImageSharp.fixed} alt="Adobe Fund for Design" />
            <a href="https://polypane.app">
              <img className={styles.pp} src={data.polypane.publicURL} alt="Polypane browser for responsive design" />
            </a>
          </p>

          <h2>Plugins for</h2>
          <p className={styles.fw}>
            <a href="/xd-plugin/">
              <img className={styles.icon} src={data.xd.publicURL} alt="Adobe XD" data-tip="Adobe XD" />
            </a>
            <a href="/figma-plugin/">
              <img className={styles.icon} src={data.figma.publicURL} alt="Figma" data-tip="Figma" />
            </a>
            <img
              className={[styles.icon, styles.disabled].join(' ')}
              src={data.sketch.publicURL}
              alt="Sketch"
              data-tip="Sketch plugin coming soon"
            />
          </p>
          <h2>Exports to</h2>
          <p className={styles.fw}>
            <img className={styles.icon} src={data.css.publicURL} alt="CSS" data-tip="CSS" />
            <img className={styles.icon} src={data.sass.publicURL} alt="Scss" data-tip="Scss" />
            <img className={styles.icon} src={data.js.publicURL} alt="Javascript" data-tip="Javascript" />
            <img className={styles.icon} src={data.android.publicURL} alt="Android" data-tip="Android" />
            <img
              className={[styles.icon, styles.disabled].join(' ')}
              src={data.swift.publicURL}
              alt="Swift"
              data-tip="Swift export coming soon"
            />
          </p>
        </div>
      </section>
    )}
  />
);

export const query = graphql`
  query {
    adobeFund: file(relativePath: { eq: "images/adobe-fund.png" }) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    polypane: file(relativePath: { eq: "images/polypane.svg" }) {
      publicURL
    }
    xd: file(relativePath: { eq: "images/xd.svg" }) {
      publicURL
    }
    sketch: file(relativePath: { eq: "images/sketch.svg" }) {
      publicURL
    }
    figma: file(relativePath: { eq: "images/figma.svg" }) {
      publicURL
    }
    css: file(relativePath: { eq: "images/css.svg" }) {
      publicURL
    }
    sass: file(relativePath: { eq: "images/sass.svg" }) {
      publicURL
    }
    js: file(relativePath: { eq: "images/js.svg" }) {
      publicURL
    }
    swift: file(relativePath: { eq: "images/swift.svg" }) {
      publicURL
    }
    android: file(relativePath: { eq: "images/android.svg" }) {
      publicURL
    }
  }
`;

export default Columns;

import React from 'react';


const styles = require('./Reading-styles.module.css');

const readingList = [
  {
    url: 'https://matthewstrom.com/writing/the-design-system-you-already-have.html',
    title: 'The Design System You Already Have',
    author: 'Matthew Ström',
    description: `Starting a design system can be overwhelming. Remember that your production code already has all the information, so use that to your advantage.`,
  },
  {
    url: 'https://daneden.me/2019/03/29/designing-for-systems/',
    title: 'Designing And Building For Systems',
    author: 'Daniel Eden',
    description: `What do you need to create a design system; whether it's just for you, for a couple dozen people or for hundreds.`,
  },
  {
    url: 'https://booking.design/lessons-learned-building-a-design-system-at-scale-10ad8500e8aa',
    title: 'Lessons learned building a design system at scale',
    author: 'Arda Karaçizmeli',
    description: 'Lessons learnt creating the Booking design system. What are the pitfalls and how to deal with them.',
  },
  {
    url: 'https://adele.uxpin.com/',
    title: 'Publicly available design systems and pattern libraries',
    author: 'UXPin',
    description: `Looking for inspiration? This repository has over 80 publicly available design systems. Have a look at design systems for Dropbox, Github, Trello and more`,
  },
];

const Article = props => {
  const { url, title, author, description } = props;

  return (
    <a href={url} className={styles.article}>
      <h3>{title}</h3>
      <small>{author}</small>
      <p>{description}</p>
    </a>
  );
};

function Reading() {
  return (
    <div className={styles.readingList}>
      <h2 className={styles.h2}>Learn more about design systems</h2>
      <p className={styles.intro}>
        Just getting started? We've collected some of our favorite articles and resources on creating design systems.
      </p>

      <div className={styles.articles}>
        {readingList.map(props => (
          <Article {...props} />
        ))}
      </div>
    </div>
  );
}

export default Reading;
